import { ComponentType } from 'react'

import { useChangeBackground } from '../../hooks/intersectionObserver.hook'

// eslint-disable-next-line @typescript-eslint/comma-dangle
const withBackgroundColor = (color: string, threshold = 0.8) => <P,>(
  WrappedComponent: ComponentType<P>,
): React.FC<P> => (props: P) => {
  const { elementRef } = useChangeBackground(color, threshold)

  return <WrappedComponent {...props} ref={elementRef} />
}

export default withBackgroundColor
