import classNames from 'classnames'
import { ForwardedRef, forwardRef } from 'react'

import Heading from '../../../../components/Heading/Heading'
import withBackgroundColor from '../../../../components/withColor/withColor'
import { BasicN30 } from '../../../../constants/color.constants'
import Message from '../Message/Message'

import styles from './About.module.scss'

const ABOUT_MESSAGE_DATA = [
  'Find',
  { key: 'hearts' },
  'your',
  'perfect',
  'match',
  'on Synanti,',
  { key: 'vampire' },
  'where',
  { key: 'glass' },
  'respect',
  'and',
  'thoughtful',
  'gestures',
  'are',
  'valued.',
  { key: 'glasses' },
]

const About = forwardRef((_, ref: ForwardedRef<HTMLElement>) => (
  <section className={classNames('container d-flex', styles.main)} ref={ref}>
    <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.title)}>
      What is Synanti?
    </Heading>
    <Message list={ABOUT_MESSAGE_DATA} />
  </section>
))

export default withBackgroundColor(BasicN30, 0.1)(About)
