import { useEffect } from 'react'

import { DEFAULT_META } from '../constants/meta.constants'

// eslint-disable-next-line import/prefer-default-export
export const usePageTitle = (title: string = DEFAULT_META.title) => {
  useEffect(() => {
    document.title = title
  }, [title])
}
