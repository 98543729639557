import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './Button.module.scss'

type ButtonType = 'primary' | 'secondary' | 'tetriary'
type ButtonSize = 'small' | 'medium' | 'large'

export interface IButtonProps {
  label?: string;
  className?: string;
  type?: ButtonType;
  size?: ButtonSize;
  onClick?: (e: React.MouseEvent) => void | undefined;
  to?: string;
  toId?: string;
  wide?: boolean;
  submit?: boolean;
  isNewTab?: boolean;
  submitting?: boolean;
  tabletHalfWidth?: boolean;
  preventActiveClassName?: boolean;
}

const getNewTabProps = (isNewTab = false) => (!isNewTab ? {} : {
  target: '_blank',
  rel: 'noopener noreferrer',
})

const Button = ({
  label,
  className,
  children,
  onClick,
  to,
  toId,
  type = 'primary',
  size = 'medium',
  wide = false,
  submit = false,
  isNewTab = false,
  submitting = false,
  preventActiveClassName = false,
}: PropsWithChildren<IButtonProps>) => {
  const labelOrChildren = label || children

  const content = (
    <>
      {labelOrChildren}
      <span className={classNames('d-flex-center', styles.label)}>
        {labelOrChildren}
      </span>
    </>
  )

  const elClassName = classNames(
    styles.main,
    styles[type],
    styles[size],
    wide && styles.wide,
    className,
    (type !== 'tetriary') && styles.ripple,
  )

  if (toId) {
    return (
      <a
        {...getNewTabProps(isNewTab)}
        href={`#${toId}`}
        onClick={onClick}
        className={elClassName}
      >
        {content}
      </a>
    )
  }

  if (to) {
    return (
      <NavLink
        {...getNewTabProps(isNewTab)}
        to={to}
        className={({ isActive }) => classNames(elClassName, !preventActiveClassName && isActive && styles.active)}
      >
        {content}
      </NavLink>
    )
  }

  return (
    <button className={elClassName} onClick={onClick} type={submit ? 'submit' : 'button'} disabled={submitting}>
      {content}
    </button>
  )
}

export default Button
