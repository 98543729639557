import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'

import {
  DESKTOP_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../constants/viewport.constants'

const hasWindow = typeof window !== 'undefined'

const initialValues = {
  width: 0,
}

const ViewportContext = createContext(initialValues)

export const ViewportProvider = ({ children }: { children: ReactNode }) => {
  const [width, setWidth] = useState(initialValues.width)

  const handleWindowResize = useCallback(() => {
    setWidth(hasWindow ? window.innerWidth : MOBILE_BREAKPOINT)
  }, [])

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return (
    <ViewportContext.Provider value={{ width }}>
      {children}
    </ViewportContext.Provider>
  )
}

export const useViewport = () => {
  const { width } = useContext(ViewportContext)

  const isMobile = width < TABLET_BREAKPOINT
  const isTablet = width >= TABLET_BREAKPOINT
  const isLaptop = width >= LAPTOP_BREAKPOINT
  const isDesktop = width >= DESKTOP_BREAKPOINT

  return { hasWidth: !!width, isMobile, isTablet, isLaptop, isDesktop }
}
