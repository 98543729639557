import { IconProps } from 'react-toastify/dist/components'

import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg'
import { ReactComponent as TickIcon } from '../../assets/icons/tick-icon.svg'

const getIconByToastType = (type: IconProps['type']) => {
  switch (type) {
    case 'error':
      return <ErrorIcon />
    default:
      return <TickIcon />
  }
}

const ToastIcon = ({ type }: IconProps) => getIconByToastType(type)

// doesn't work with memo
export default ToastIcon
