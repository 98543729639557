import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Heading from '../../../../components/Heading/Heading'
import Text from '../../../../components/Text/Text'
import WaitlistButton from '../../../../components/WaitlistButton/WaitlistButton'
import { useViewport } from '../../../../contexts/Viewport.context'

import styles from './Hero.module.scss'

const PHOTO_COUNT = 9
const INITIAL_PHOTOS = [1, 2]
const PHOTO_INDEXES = Array.from({ length: PHOTO_COUNT }, (_, i) => i + 1)

const PhotoGenerator = () => {
  const [currentIndexes, setIndexes] = useState(INITIAL_PHOTOS)

  const updateIndexes = useCallback(() => {
    const [first, last] = currentIndexes

    if (last === PHOTO_COUNT) {
      setIndexes([PHOTO_COUNT])
    } else if (last % 3 === 0) {
      setIndexes([last])
    } else if (last === undefined) {
      if (first === PHOTO_COUNT) {
        setIndexes([PHOTO_COUNT, 1])
      } else {
        setIndexes([first, first + 1])
      }
    } else {
      setIndexes([last, last + 1])
    }
  }, [currentIndexes, setIndexes])

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateIndexes()
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  })

  return (
    <div className={styles.generator}>
      {PHOTO_INDEXES.map((index) => (
        <img
          key={index}
          alt={`People #${index}`}
          src={`/assets/hero/large/${index}.png`}
          style={{ zIndex: index % 3 === 0 ? 0 : index }}
          className={classNames(index % 2 === 0 && styles.right, currentIndexes.includes(index) && styles.visible)}
        />
      ))}
    </div>
  )
}

const PhotoSwiper = () => {
  const { hasWidth, isLaptop } = useViewport()

  if (!hasWidth) {
    return null
  }

  return (
    <div className={styles.swiper}>
      {isLaptop ? (
        <PhotoGenerator />
      ) : (
        <Swiper
          loop
          autoplay
          slidesPerView={1}
          modules={[Autoplay]}
          spaceBetween={0}
          className="hero-swiper"
        >
          {PHOTO_INDEXES.map((index) => (
            <SwiperSlide key={index} className={classNames('d-flex', styles.card)}>
              <img src={`/assets/hero/${index}.png`} alt={`People #${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

const Hero = () => (
  <section className={styles.main}>
    <PhotoSwiper />
    <div className={classNames('container-h-margin d-flex', styles.content)}>
      <Heading as="h1" className={classNames('heading mobile-h1 desktop-h1', styles.title)}>
        <span>
          Find a perfect match.
        </span>
        <span>
          Guaranteed.
        </span>
      </Heading>
      <Text className={classNames('text1', styles.subtitle)}>
        <span>
          Experience reimagined dating done right, stress-free.
        </span>
        <span>
          Safe and honest dates or money back.
        </span>
      </Text>
      <WaitlistButton type="primary" narrow />
    </div>
  </section>
)

export default Hero
