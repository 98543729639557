import classNames from 'classnames'
import { forwardRef } from 'react'

import Heading from '../../../../components/Heading/Heading'
import Text from '../../../../components/Text/Text'
import withBackgroundColor from '../../../../components/withColor/withColor'
import { BasicN30 } from '../../../../constants/color.constants'
import { useViewport } from '../../../../contexts/Viewport.context'
import GetSynanti from '../GetSynanti/GetSynanti'

import styles from './Priorities.module.scss'

interface IPriorityCardProps {
  priorityKey: 'security' | 'fair' | 'ease';
  className?: string;
}

interface IPriorityCardImageProps {
  image: string;
}

/* eslint-disable max-len */
const PRIORITIES_DATA = {
  security: {
    title: 'Security and trust',
    description: 'All profiles are verified to ensure a trustworthy environment. We promote open and respectful communication to prevent any form of exploitation.',
    images: [
      'one',
      'two',
      'three',
    ],
  },
  fair: {
    title: 'Fair deal',
    description: 'Set clear expectations for your dates to avoid misunderstandings. Our platform fosters a culture of respect and fairness for all users.',
    images: [
      'match',
      'left',
      'right',
      'heart',
      'heart',
    ],
  },
  ease: {
    title: 'Ease and convenience',
    description: 'Enjoy a seamless user experience. Quick registration allows you to start connecting with like-minded individuals in minutes.',
    images: [
      'buttons',
      'wrapper',
      'messages',
    ],
  },
}
/* eslint-enable max-len */

const PriorityCardImage = ({ image }: IPriorityCardImageProps) => {
  if (image !== 'buttons') {
    return (
      <div className={classNames(styles.image, styles[image])} />
    )
  }

  return (
    <div className={classNames(styles.image, styles.buttons)}>
      <div className={classNames(styles.image, styles.dislike)} />
      <div className={classNames(styles.image, styles.like)} />
      <div className={classNames(styles.image, styles.star)} />
    </div>
  )
}

const PriorityCard = ({ priorityKey, className }: IPriorityCardProps) => {
  const value = PRIORITIES_DATA[priorityKey]
  const { isLaptop } = useViewport()

  return (
    <div className={classNames('d-flex', styles.card, styles[priorityKey], className)}>
      <Heading as="h3" className={classNames('heading mobile-subheading1 desktop-subheading1', styles.title)}>
        {value.title}
      </Heading>
      <Text className={classNames('text2', styles.description)}>
        {value.description}
      </Text>
      {isLaptop ? value.images.map((image, index) => (
        <PriorityCardImage key={`${image}-${index}`} image={image} />
      )) : (
        <img src={`/assets/priorities/${priorityKey}.png`} alt={priorityKey} />
      )}
    </div>
  )
}

const PrioritiesStackTwoColumn = () => (
  <div className={styles.wrapper}>
    <PriorityCard priorityKey="security" />
    <div className={classNames('d-flex', styles.right)}>
      <PriorityCard priorityKey="ease" />
      <PriorityCard priorityKey="fair" />
    </div>
  </div>
)

const PrioritiesStack = () => {
  const { isLaptop } = useViewport()

  if (isLaptop) {
    return <PrioritiesStackTwoColumn />
  }

  return (
    <>
      {Object.keys(PRIORITIES_DATA).map((key) => (
        <PriorityCard key={key} priorityKey={key as keyof typeof PRIORITIES_DATA} className="container-h-margin" />
      ))}
      <GetSynanti />
    </>
  )
}

const Priorities = forwardRef((_, ref: React.LegacyRef<HTMLElement>) => (
  <section className={classNames('d-flex', styles.main)} ref={ref}>
    <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2 container-h-margin', styles.heading)}>
      Stress-free dating
    </Heading>
    <div className={styles.cards}>
      <PrioritiesStack />
    </div>
  </section>
))

export default withBackgroundColor(BasicN30, 0.5)(Priorities)
