import classNames from 'classnames'
import { Link } from 'react-router-dom'

import WaitlistButton from '../WaitlistButton/WaitlistButton'

import styles from './Header.module.scss'

const Header = () => (
  <header className={styles.main}>
    <div className={classNames('layout container', styles.inner)}>
      <Link to="/" className={styles.logo}>
        <img src="/assets/logo.png" alt="Synanti logo" className={styles.logo} />
      </Link>
      <nav className={styles.links}>
        <WaitlistButton narrow />
      </nav>
    </div>
  </header>
)

export default Header
