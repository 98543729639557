import classNames from 'classnames'
import { CloseButtonProps } from 'react-toastify/dist/components'

import { ReactComponent as CrossIcon } from '../../assets/icons/cross-icon.svg'
import IconButton from '../IconButton/IconButton'

import styles from './Toast.module.scss'

const ToastCloseButton = ({ closeToast }: CloseButtonProps) => (
  <IconButton onClick={closeToast} icon={CrossIcon} className={classNames('Toastify__toast-close', styles.close)} />
)

// doesn't work with memo
export default ToastCloseButton
