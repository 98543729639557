import { PropsWithChildren } from 'react'

import META from '../../constants/meta.constants'
import { usePageTitle } from '../../hooks/meta.hooks'

interface IPageProps {
  route?: string;
}

const Page = ({ children, route }: PropsWithChildren<IPageProps>) => {
  const meta = route ? META[route] : undefined
  usePageTitle(meta?.title)

  return (
    <article>
      {children}
    </article>
  )
}

export default Page
