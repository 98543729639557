import classNames from 'classnames'
import { forwardRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import Heading from '../../../../components/Heading/Heading'
import Text from '../../../../components/Text/Text'
import withBackgroundColor from '../../../../components/withColor/withColor'
import { SecondaryYellowY30 } from '../../../../constants/color.constants'

import styles from './HowItWorks.module.scss'

const STEPS_DATA = {
  create: {
    title: 'Create profile',
    description: 'Share your photos, interests and preferences. Your privacy is our top priority.',
  },
  choose: {
    title: 'Choose a partner',
    description: 'Propose a date and a thoughtful gesture of appreciation. The suggested amount will be held securely.',
  },
  accept: {
    title: 'Accept or decline',
    description: 'The potential partner will receive a notification and can decide.',
  },
  enjoy: {
    title: 'Enjoy your date!',
    description: 'If both agree, the date is set. Payment is released after both confirm the date.',
  },
}

const HowItWorks = forwardRef((_, ref: React.LegacyRef<HTMLElement>) => (
  <section className={classNames('container d-flex', styles.main)} ref={ref}>
    <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.heading)}>
      How it works
    </Heading>
    <div className={styles.swiper}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        className="how-swiper"
      >
        {Object.entries(STEPS_DATA).map(([key, value], index) => (
          <SwiperSlide key={key} className={classNames('d-flex', styles.card, styles[key])}>
            <div className={classNames('d-flex-center', styles.index)}>
              <Text as="span" className="d-flex-center young-serif">
                {index + 1}
              </Text>
            </div>
            <Text className={classNames(styles.title, 'text1')}>
              {value.title}
            </Text>
            <Text className={classNames(styles.description, 'text2')}>
              {value.description}
            </Text>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </section>
))

export default withBackgroundColor(SecondaryYellowY30)(HowItWorks)
