import { PropsWithChildren } from 'react'
import { useFormState } from 'react-final-form'

import Button, { IButtonProps } from '../Button/Button'

const SubmitButton = ({ children, ...other }: PropsWithChildren<IButtonProps>) => {
  const { submitting } = useFormState()

  return (
    <Button {...other} submit submitting={submitting} >
      {children}
    </Button>
  )
}

export default SubmitButton
