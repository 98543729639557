import classNames from 'classnames'
import { Outlet } from 'react-router-dom'

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { ViewportProvider } from '../../contexts/Viewport.context'

import styles from './Base.layout.module.scss'

const BaseLayout = () => (
  <ViewportProvider>
    <div className={classNames('d-flex', styles.main)}>
      <Header />
      <main className={classNames('layout', styles.layout)}>
        <Outlet />
      </main>
      <Footer />
    </div>
  </ViewportProvider>
)

export default BaseLayout
