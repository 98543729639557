import React from 'react'
import ReactDOM, { hydrateRoot } from 'react-dom/client'
import 'swiper/css'

import reportWebVitals from './reportWebVitals'
import Router from './routes'

import './styles/index.scss'

if (process.env.NODE_ENV === 'production') {
  hydrateRoot(
    document.getElementById('root') as HTMLElement,
    <React.StrictMode>
      <Router />
    </React.StrictMode>,
  )
} else {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  )

  root.render(
    <React.StrictMode>
      <Router />
    </React.StrictMode>,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
