import classNames from 'classnames'
import { Field } from 'react-final-form'

import { ReactComponent as MailSvg } from './assets/mail.svg'
import FormError from './FormError'
import { getFieldError } from './FormField.utils'

import styles from './EmailInput.module.scss'

interface IInputProps {
  id: string;
  className?: string;
  maxLength?: number;
}

const EmailInput = ({ id, className, maxLength = 100 }: IInputProps) => (
  <Field
    name={id}
    render={({ input, meta }) => (
      <label
        htmlFor={id}
        className={classNames('d-flex svg-stroke-current-color', styles.main, !!getFieldError(meta) && styles.invalid)}
      >
        <MailSvg className={styles.icon} />
        <input
          {...input}
          id={id}
          type="email"
          maxLength={maxLength}
          placeholder="Your email"
          disabled={meta.submitting}
          className={classNames('text2', className)}
        />
        <FormError name={input.name} className={styles.error} />
      </label>
    )}
  />
)

export default EmailInput
