import classNames from 'classnames'

import styles from './Loader.module.scss'

interface ILoaderProps {
  className?: string;
}

const Loader = ({ className }: ILoaderProps) => (
  <div className={classNames(styles.main, className)}>
    <div className={styles.progress} />
    <span className={styles.overlay} />
  </div>
)

export default Loader
