import classNames from 'classnames'
import { PropsWithChildren } from 'react'

type TextType = 'span' | 'p' | 'div'

interface IHeadingProps {
  as?: TextType;
  label?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Text = ({ as = 'p', label, className, style, children }: PropsWithChildren<IHeadingProps>) => {
  const Element = as

  return (
    <Element className={classNames(className)} style={style}>
      {label || children}
    </Element>
  )
}

export default Text
