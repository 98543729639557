import { useEffect, useRef, useState } from 'react'

import { useViewport } from '../contexts/Viewport.context'

export const useIsVisible = (threshold = 0.1) => {
  const elementRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const currentEl = elementRef.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.unobserve(entry.target)
        }
      },
      { threshold },
    )

    if (currentEl) {
      observer.observe(currentEl)
    }

    return () => {
      if (observer && currentEl) {
        observer.unobserve(currentEl)
      }
    }
  }, [threshold])

  return { elementRef, isVisible }
}

const hexToRgb = (value: string) => {
  let hex = value.replace('#', '')

  if (hex.length === 3) {
    hex = hex.split('').map((char) => char + char).join('')
  }

  const bigint = parseInt(hex, 16)

  /* eslint-disable no-bitwise */
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  /* eslint-enable no-bitwise */

  return [r, g, b]
}

const setPageColor = (color: string) => {
  document.body.style.backgroundColor = color
  const header = document.querySelector('header')

  if (header) {
    const rgbColor = hexToRgb(color)
    header.style.backgroundColor = `rgba(${rgbColor.join(', ')}, 0.9)`
  }
}

export const useChangeBackground = (color: string, threshold = 1) => {
  const elementRef = useRef(null)
  const { isDesktop } = useViewport()

  useEffect(() => {
    if (!isDesktop) {
      return undefined
    }

    const currentEl = elementRef.current

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setPageColor(color)
      }
    }, { threshold })

    if (currentEl) {
      observer.observe(currentEl)
    }

    return () => {
      if (observer && currentEl) {
        observer.unobserve(currentEl)
      }
    }
  }, [isDesktop, color, threshold])

  return { elementRef }
}
