import classNames from 'classnames'
import { useField } from 'react-final-form'

import { ReactComponent as WarningIcon } from './assets/warning.svg'
import { getFieldError } from './FormField.utils'

import styles from './FormError.module.scss'

interface IFormErrorProps {
  name: string;
  className?: string;
}

const FormError = ({ name, className }: IFormErrorProps) => {
  const { meta } = useField(name)
  const error = getFieldError(meta)

  if (!error) {
    return null
  }

  return (
    <p className={classNames('svg-current-stroke-color text3', styles.main, className)}>
      <WarningIcon />
      {error}
    </p>
  )
}

export default FormError
