import classNames from 'classnames'

import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import Page from '../../components/Page/Page'

import styles from './NotFound.module.scss'

const NotFoundRoute = () => (
  <Page>
    <section className="container">
      <div className={classNames('d-flex-center', styles.main)}>
        <Heading as="h1" className={classNames('heading mobile-h1 desktop-h1', styles.title)}>
          404 – Page not found
        </Heading>
        <div className={styles.button}>
          <Button to="/" wide>
            To home page
          </Button>
        </div>
      </div>
    </section>
  </Page>
)

export default NotFoundRoute
