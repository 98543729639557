import { useCallback } from 'react'

import Button, { IButtonProps } from '../Button/Button'

interface IWaitlistButtonProps extends IButtonProps {
  label?: string;
  narrow?: boolean;
}

const WaitlistButton = ({
  label = 'Join waitlist',
  type = 'secondary',
  narrow = false,
  ...other
}: IWaitlistButtonProps) => {
  const scrollToWaitlist = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
    const emailInput = document.getElementById('email')
    const waitlistSection = document.getElementById('waitlist')

    waitlistSection?.scrollIntoView({ behavior: 'smooth' })

    setTimeout(() => {
      emailInput?.focus()
      waitlistSection?.scrollIntoView({ behavior: 'smooth' })
    }, 500)

    event.stopPropagation()
  }, [])

  return (
    <Button {...other} type={type} wide={!narrow} toId="email" onClick={scrollToWaitlist}>
      {label}
    </Button>
  )
}

export default WaitlistButton
