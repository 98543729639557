import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Form, useFormState } from 'react-final-form'

import EmailInput from '../../../../components/FormField/EmailInput'
import Heading from '../../../../components/Heading/Heading'
import Loader from '../../../../components/Loader/Loader'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'
import Text from '../../../../components/Text/Text'
import { SecondaryYellowY30 } from '../../../../constants/color.constants'
import { useChangeBackground } from '../../../../hooks/intersectionObserver.hook'

import styles from './Waitlist.module.scss'

interface IWaitlistFormValues {
  email: string;
}

interface IWaitlistFormResponse {
  success: boolean;
  message?: string;
}

interface IWaitlistFormProps {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
}

interface IWaitlistFormContentProps {
  type: 'initial' | 'success';
}

const API_URL = process.env.REACT_APP_API_URL

const WAITLIST_FORM_INITIAL_VALUES: IWaitlistFormValues = {
  email: '',
}

/* eslint-disable max-len */
const FORM_CONTENT = {
  initial: {
    image: 'hearts',
    title: 'Want to boost your chances of finding love?',
    description: 'Join our waitlist and increase your chances of connecting with someone special on Synanti.',
  },
  success: {
    image: 'cat',
    title: 'Congrats! You\'re in!',
    description: 'You\'ve secured your spot on the Synanti waitlist, and we couldn\'t be more excited to have you on board. Get ready to experience the future of dating.',
  },
}
/* eslint-enable max-len */

const WaitlistFormContent = ({ type }: IWaitlistFormContentProps) => {
  const { image, title, description } = useMemo(() => FORM_CONTENT[type], [type])

  return (
    <>
      <div className={styles.info}>
        <img src={`/assets/${image}.png`} alt={image} className={styles[image]} />
        <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.heading)}>
          {title}
        </Heading>
        <Text className="text1">
          {description}
        </Text>
      </div>
      {type !== 'initial' && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </>
  )
}

const WaitlistForm = ({ handleSubmit }: IWaitlistFormProps) => {
  const { submitSucceeded } = useFormState()

  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (submitSucceeded) {
      setSubmitting(true)

      setTimeout(() => {
        setSubmitting(false)
        setSuccess(true)
      }, 1000)
    }
  }, [submitSucceeded])

  return (
    <div
      className={classNames('d-flex', styles.inner, submitting && styles.submitting, success && styles.success)}
    >
      <div className={classNames(styles.content, styles.initial)}>
        <WaitlistFormContent type="initial" />
        <form onSubmit={handleSubmit}>
          <EmailInput id="email" />
          <SubmitButton type="secondary">
            Join now
          </SubmitButton>
        </form>
      </div>
      {(submitting || success) && (
        <div className={classNames(styles.content, styles.submitted)}>
          <WaitlistFormContent type="success" />
        </div>
      )}
    </div>
  )
}

const Waitlist = () => {
  const { elementRef } = useChangeBackground(SecondaryYellowY30)

  const onSubmit = async (values: IWaitlistFormValues) => {
    try {
      const response = await fetch(`${API_URL}/v1/send-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      if (response.status === 429) {
        return { email: 'Too many requests' }
      }

      const data: IWaitlistFormResponse = await response.json()

      if (!data.success) {
        return data
      }

      return null
    } catch (error) {
      console.error('ERROR', error)

      return { email: 'Internal server error. Try again later' }
    }
  }

  return (
    <section className={classNames('container', styles.main)} ref={elementRef} id="waitlist">
      <Form
        className={styles.form}
        onSubmit={onSubmit}
        initialValues={WAITLIST_FORM_INITIAL_VALUES}
        render={({ handleSubmit }) => (
          <WaitlistForm handleSubmit={handleSubmit} />
        )}
      />
    </section>
  )
}

export default Waitlist
