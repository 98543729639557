/* eslint-disable max-len */
import { IMeta } from '../types/meta.types'
import ROUTES from './routes.constants'

export const DEFAULT_META: IMeta = {
  title: 'Synanti — A Transparent and Innovative Online Dating Platform',
  description: 'Synanti is a revolutionary online dating platform that offers a unique and transparent approach to setting up meetings and ensuring meaningful connections. With a focus on fairness, safety, and clarity, Synanti creates an environment where both parties benefit from honest interactions. Join the waitlist for early access to the platform and experience a new way of dating.',
  keywords: 'online dating, transparent dating, innovative dating platform, safe interactions, meaningful connections, Synanti, modern dating, fair relationships, secure dating platform, trustworthy matchmaking, dating with integrity, unique dating experience',
  image: 'https://synanti.xyz/social.png',
  url: 'https://synanti.xyz',
}

export default {
  [ROUTES.home]: DEFAULT_META,
} as Record<string, IMeta>
