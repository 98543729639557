import classNames from 'classnames'

import Text from '../../../../components/Text/Text'

import styles from './Message.module.scss'

export type TMessageItem = string | {
  key: string;
}

interface IMessageProps {
  list: TMessageItem[];
  className?: string;
}

const Message = ({ list, className }: IMessageProps) => (
  <div className={classNames(styles.main, className)}>
    {list.map((item, index) => {
      if (typeof item === 'string') {
        return (
          <Text key={`${item}-${index}`} as="span" className="text1">
            {item}
          </Text>
        )
      }

      return (
        <img src={`/assets/message/${item.key}.png`} key={item.key} alt={item.key.toLocaleUpperCase()} />
      )
    })}
  </div>
)

export default Message
