import classNames from 'classnames'
import { forwardRef } from 'react'

import Heading from '../../../../components/Heading/Heading'
import Loader from '../../../../components/Loader/Loader'
import WaitlistButton from '../../../../components/WaitlistButton/WaitlistButton'
import withBackgroundColor from '../../../../components/withColor/withColor'
import { SecondaryVioletV30 } from '../../../../constants/color.constants'
import Message from '../Message/Message'

import styles from './GetSynanti.module.scss'

const GET_SYNANTI_MESSAGE_DATA = [
  'Boost',
  'your',
  'chances',
  'to',
  'find',
  'your',
  'match',
  'with',
  'Synanti',
  'and',
  'become',
  'a',
  'part',
  'of a new',
  'era',
  'of dating.',
  { key: 'hearts' },
]

const GetSynanti = forwardRef((_, ref: React.LegacyRef<HTMLElement>) => (
  <section className={classNames('container d-flex-center', styles.main)} ref={ref}>
    <div className={classNames('d-flex', styles.inner)}>
      <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.heading)}>
        Get Synanti first
      </Heading>
      <div className={styles.form}>
        <Loader className={styles.loader} />
        <WaitlistButton size="large" label="Boost my dates" narrow />
      </div>
      <Message list={GET_SYNANTI_MESSAGE_DATA} />
    </div>
  </section>
))

export default withBackgroundColor(SecondaryVioletV30)(GetSynanti)
