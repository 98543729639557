import classNames from 'classnames'
import { PropsWithChildren } from 'react'

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

interface IHeadingProps {
  as?: HeadingType;
  label?: string;
  className?: string;
}

const Heading = ({ as = 'h1', label, className, children }: PropsWithChildren<IHeadingProps>) => {
  const Element = as

  return (
    <Element className={classNames(className)}>
      {label || children}
    </Element>
  )
}

export default Heading
