import Page from '../../components/Page/Page'
import ROUTES from '../../constants/routes.constants'
import { useViewport } from '../../contexts/Viewport.context'
import About from './components/About/About'
import Benefits from './components/Benefits/Benefits'
import GetSynanti from './components/GetSynanti/GetSynanti'
import Hero from './components/Hero/Hero'
import HowItWorks from './components/HowItWorks/HowItWorks'
import Priorities from './components/Priorities/Priorities'
import Token from './components/Token/Token'
import Waitlist from './components/Waitlist/Waitlist'

const HomeRoute = () => {
  const { isLaptop } = useViewport()

  return (
    <Page route={ROUTES.home}>
      <Hero />
      <About />
      <Benefits />
      <HowItWorks />
      <Priorities />
      {isLaptop && <GetSynanti />}
      <Token />
      <Waitlist />
    </Page>
  )
}

export default HomeRoute
