import classNames from 'classnames'
import { forwardRef, useCallback, useState } from 'react'

import Heading from '../../../../components/Heading/Heading'
import Text from '../../../../components/Text/Text'
import WaitlistButton from '../../../../components/WaitlistButton/WaitlistButton'
import withBackgroundColor from '../../../../components/withColor/withColor'
import { SecondaryPinkP30 } from '../../../../constants/color.constants'

import styles from './Token.module.scss'

interface ITokenCard {
  image: string;
  title: string;
  description: string;
  hasButton?: boolean;
}

/* eslint-disable max-len */
const TOKEN_DATA: Record<string, ITokenCard> = {
  tokens: {
    image: 'arrows',
    title: 'Tokens',
    description: 'Use tokens to pay for or receive payment for dates, offering flexibility and convenience. Tokens can be converted into real currency.',
  },
  growth: {
    image: 'mushroom',
    title: 'Token growth',
    description: ' Our unique tokenomics model ensures that the value of Synanti tokens appreciates over time.',
  },
  join: {
    image: 'key',
    title: 'Join the waitlist',
    description: 'Be among the first to invest in our exciting token sale and unlock exclusive benefits!',
    hasButton: true,
  },
}
/* eslint-enable max-len */

const TokenCard = (value: ITokenCard) => {
  const [isHovered, setHovered] = useState(false)

  const setHoverOn = useCallback(() => {
    setHovered(true)
  }, [])

  const setHoverOff = useCallback(() => {
    setHovered(false)
  }, [])

  return (
    <div
      onMouseEnter={setHoverOn}
      onMouseLeave={setHoverOff}
      onTouchStart={setHoverOn}
      onTouchEnd={setHoverOff}
      className={classNames('d-flex', styles.card, !isHovered && styles.shake)}
    >
      <img src={`/assets/${value.image}.png`} alt={value.image.toLocaleUpperCase()} />
      <Heading as="h3" className={classNames('heading mobile-subheading2 desktop-subheading2', styles.title)}>
        {value.title}
      </Heading>
      <Text>
        {value.description}
      </Text>
      {value.hasButton && <WaitlistButton />}
    </div>
  )
}

const Token = forwardRef((_, ref: React.LegacyRef<HTMLElement>) => (
  <section className={classNames('container d-flex', styles.main)} ref={ref}>
    <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.heading)}>
      Crypto token Synanti
    </Heading>
    <Text className={classNames('text1', styles.description)}>
      Discover the future of dating with Synanti’s innovative crypto token. Our token-based economy creates a
      sustainable ecosystem within the platform, rewarding users for their participation.
    </Text>
    <div className={styles.cards}>
      {Object.entries(TOKEN_DATA).map(([key, value]) => (
        <TokenCard key={key} {...value} />
      ))}
    </div>
  </section>
))

export default withBackgroundColor(SecondaryPinkP30)(Token)
