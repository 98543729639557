import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './IconButton.module.scss'

interface IconButtonProps {
  icon: React.FunctionComponent;
  title?: string;
  to?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  size?: number;
  className?: string;
  disabled?: boolean;
}

const IconButton = ({
  onClick,
  to,
  title,
  icon,
  className,
  disabled = false,
}: IconButtonProps) => {
  const commonProps = {
    title,
    onClick,
    className: classNames('svg-current-color', styles.main, { [styles.disabled]: disabled }, className),
  }

  const IconEl = icon

  if (to) {
    return (
      <Link {...commonProps} to={to}>
        <IconEl />
      </Link>
    )
  }

  return (
    <button {...commonProps} type="button" disabled={disabled}>
      <IconEl />
    </button>
  )
}

export default IconButton
