import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import { useIsVisible } from '../../hooks/intersectionObserver.hook'

import styles from './AnimateOnScroll.module.scss'

interface IAnimateOnScrollProps {
  className?: string;
}

const AnimateOnScroll = ({ children, className }: PropsWithChildren<IAnimateOnScrollProps>) => {
  const { elementRef, isVisible } = useIsVisible()

  return (
    <section
      ref={elementRef}
      className={classNames(styles.main, isVisible && styles.show, className)}
    >
      {children}
    </section>
  )
}

export default AnimateOnScroll
