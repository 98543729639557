import { Link } from 'react-router-dom'

import Button from '../Button/Button'
import WaitlistButton from '../WaitlistButton/WaitlistButton'

import styles from './Footer.module.scss'

const LINKS = {
  privacy: {
    isNewTab: true,
    to: '/docs/synanti_privacy_policy.pdf',
    label: 'Privacy',
  },
  terms: {
    isNewTab: true,
    to: '/docs/synanti_terms.pdf',
    label: 'Terms',
  },
}

const Footer = () => (
  <footer className={styles.main}>
    <section className="d-flex layout container">
      <div className={styles.top}>
        <Link to="/" className={styles.logo}>
          <img src="/assets/logo.png" alt="Synanti logo" className={styles.logo} />
        </Link>
        <WaitlistButton narrow />
      </div>
      <nav>
        {Object.entries(LINKS).map(([key, value]) => (
          <li key={key}>
            <Button
              to={value.to}
              type="tetriary"
              className="text1"
              isNewTab={value.isNewTab}
            >
              {value.label}
            </Button>
          </li>
        ))}
      </nav>
    </section>
  </footer>
)

export default Footer
