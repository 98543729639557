import classNames from 'classnames'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'

import AnimateOnScroll from '../../../../components/AnimateOnScroll/AnimateOnScroll'
import Heading from '../../../../components/Heading/Heading'
import Text from '../../../../components/Text/Text'
import withBackgroundColor from '../../../../components/withColor/withColor'
import { BasicN30 } from '../../../../constants/color.constants'
import { useViewport } from '../../../../contexts/Viewport.context'

import styles from './Benefits.module.scss'

interface IBenefitsProps {
  scrollProgress: number;
}

interface IBenefitsCollageProps extends IBenefitsProps {
  setOffsetTop: (value: number) => void;
}

interface MotionOptions {
  xRatio: number;
  yRatio: number;
  flyRatio?: number;
}

const BENEFITS_DATA = {
  glass: {
    title: 'Date guaranteed',
    caption: 'Either the date will take place or the money will be returned',
  },
  diamond: {
    title: 'Respectful compensation',
    caption: 'Get honest compensation for your time, without facing exploitation and risks',
  },
  hand: {
    title: 'Transparency',
    caption: 'No bargaining, no hidden conditions - only honest and respectful relationship',
  },
}

const BENEFITS_COLLAGE_PHOTO_MAP = {
  bikini: {
    xRatio: 1.3,
    yRatio: 0,
  },
  center: {
    xRatio: 0.3,
    yRatio: 0,
    flyRatio: 20,
  },
  chips: {
    xRatio: -1.25,
    yRatio: 0,
  },
  cocktail: {
    xRatio: -2.2,
    yRatio: -0.8,
  },
  flowers: {
    xRatio: 1.5,
    yRatio: -0.6,
  },
  ice: {
    xRatio: -0.7,
    yRatio: 0,
    flyRatio: 20,
  },
  left: {
    xRatio: 2.1,
    yRatio: 0,
  },
  lips: {
    xRatio: -1,
    yRatio: -1,
  },
  lipstick: {
    xRatio: -1.5,
    yRatio: 0.7,
  },
  pizza: {
    xRatio: 0.5,
    yRatio: -1.1,
  },
  right: {
    xRatio: -1.7,
    yRatio: 0,
  },
  stone: {
    xRatio: 2,
    yRatio: 0.7,
  },
  tower: {
    xRatio: 1,
    yRatio: 0.4,
  },
  candy: {
    xRatio: -0.5,
    yRatio: 0.6,
  },
  milkshake: {
    xRatio: 1,
    yRatio: 0.9,
  },
  wine: {
    xRatio: -0.1,
    yRatio: -0.8,
  },
}

const getProgress = (value: number, offsetTop: number) => {
  const progress = ((value - offsetTop) / window.innerHeight) * (100 / 5)

  if (progress < 0) {
    return 0
  }

  if (progress > 100) {
    return 100
  }

  return parseInt(progress.toFixed(0), 10)
}

const getDiamondTopRotate = (progress: number) => {
  const rotate = (progress < 65 ? (progress - 15) : (90 - progress)) * 2

  if (rotate < 0) {
    return 0
  }

  if (rotate > 42) {
    return 42
  }

  return rotate.toFixed()
}

export const getRotate = (progress: number, index: number) => {
  const rotate = (index * (50 - progress)) / 5

  if (rotate > 50) {
    return 50
  }

  if (rotate < 0) {
    return 0
  }

  return rotate.toFixed()
}

export const getScale = (progress: number, index: number) => {
  const scale = (progress - 30) / ((2 + index) * 5)

  if (scale < 0) {
    return 0
  }

  if (scale > 1) {
    return 1
  }

  return scale.toFixed(2)
}

const getTransform = (progress: number, { xRatio, yRatio, flyRatio = 5 }: MotionOptions) => {
  const transformProgress = (progress - 50) / 35

  const transform = Math.max(transformProgress, 0)
  const ratio = progress < 85 ? transform : transform ** flyRatio

  const x = -100 * (xRatio * ratio + 0.5)
  const y = -100 * (yRatio * ratio + 0.5)

  return `${x.toFixed(2)}%, ${y.toFixed(2)}%`
}

export const getDiamondMotion = (progress: number) => {
  if (progress < 90) {
    return 'translate(0%, 0%)'
  }

  const rotate = Math.max(progress - 90, 0) * 2
  const translate = Math.max(progress - 90, 0) * 40

  return `translate(0%, -${translate.toFixed()}px) rotate(${rotate.toFixed()}deg)`
}

export const getCardsOpacity = (progress: number) => {
  if (progress > 90) {
    return 1
  }

  return 0
}

export const getCollageOpacity = (progress: number) => {
  if (progress < 98) {
    return 1
  }

  return 0
}

export const getImageOpacity = (progress: number, index: number) => {
  const scale = (progress - 30) / (index * 5)

  if (scale < 0) {
    return 0
  }

  if (scale > 1) {
    return 1
  }

  return scale.toFixed(2)
}

export const getCollageVisibility = (progress: number) => {
  if (progress >= 100) {
    return 'hidden'
  }

  return 'visible'
}

const BenefitsCards = ({ scrollProgress }: IBenefitsProps) => {
  const { isLaptop } = useViewport()

  return (
    <div style={{ opacity: isLaptop ? getCardsOpacity(scrollProgress) : 1 }} className={styles.container}>
      <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.heading)}>
        Reimagined dating experience
      </Heading>
      <div className={classNames(styles.cards)}>
        {Object.entries(BENEFITS_DATA).map(([key, value]) => (
          <AnimateOnScroll key={key} className={classNames('d-flex', styles.item, styles[key])}>
            <img
              src={`/assets/benefits-${key}.png`}
              alt={key.toLocaleUpperCase()}
            />
            <Heading as="h3" className={classNames('heading mobile-subheading2 desktop-subheading2', styles.title)}>
              {value.title}
            </Heading>
            <Text className={classNames('text2', styles.caption)}>
              {value.caption}
            </Text>
          </AnimateOnScroll>
        ))}
      </div>
    </div>
  )
}

const BenefitsCollage = ({ scrollProgress, setOffsetTop }: IBenefitsCollageProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const offsetTop = ref.current.getBoundingClientRect().top - window.innerHeight + window.scrollY
      setOffsetTop(offsetTop)
    }
  }, [setOffsetTop])

  return (
    <div
      ref={ref}
      className={classNames('d-flex-center', styles.collage)}
      style={{ visibility: getCollageVisibility(scrollProgress), opacity: getCollageOpacity(scrollProgress) }}
    >
      <div className={styles.start} style={{ transform: getDiamondMotion(scrollProgress) }}>
        <img
          src="/assets/collage/top.png"
          alt="diamond top"
          className={styles.top}
          style={{
            transform: `translate(-50%, -117%) rotate(${getDiamondTopRotate(scrollProgress)}deg)`,
          }}
        />
        <img
          src="/assets/collage/diamond.png"
          alt="diamond"
          className={styles.bottom}
        />
      </div>
      <div className={styles.photos}>
        <div className={styles.scale}>
          {Object.entries(BENEFITS_COLLAGE_PHOTO_MAP).map(([image, options], index) => (
            <img
              key={image}
              src={`/assets/collage/${image}.png`}
              alt={image}
              className={styles[image]}
              style={{
                // opacity: getImageOpacity(scrollProgress, index + 1),
                transform:
                  `scale(${getScale(scrollProgress, index + 1)})
                   translate(${getTransform(scrollProgress, options)})
                   rotate(${getRotate(scrollProgress, index + 1)}deg)`,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const Benefits = forwardRef((_, ref: ForwardedRef<HTMLElement>) => {
  const { scrollY } = useScroll()
  const { hasWidth } = useViewport()

  const [scrollProgress, setScrollProgress] = useState(0)
  const [absoluteOffsetTop, setAbsoluteOffsetTop] = useState(0)

  useMotionValueEvent(scrollY, 'change', (latest) => {
    setScrollProgress(getProgress(latest, absoluteOffsetTop))
  })

  return (
    <section className={styles.main} ref={ref}>
      <div className={classNames('container d-flex-center', styles.wrapper)}>
        <BenefitsCards scrollProgress={scrollProgress} />
        {hasWidth && <BenefitsCollage scrollProgress={scrollProgress} setOffsetTop={setAbsoluteOffsetTop} />}
      </div>
    </section>
  )
})

export default withBackgroundColor(BasicN30, 0.2)(Benefits)
